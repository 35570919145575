
.text--minified {
  font-size: .95rem;
}

.text--grey {
  color: #666;
}

.text--i {
  font-style: italic;
}

.text--label--grey {
  padding: 3px 10px;
  background-color: #eee;
}

.warning {
  background-color: #ffea9e;
  padding: 20px 40px;
  border-radius: 10px;
}

.warning__icon {

  font-family: 'geomanistblack', 'Lato', sans-serif;

  background-color: #ffcd1f;

  width: 40px;
  height: 40px;

  border-radius: 20px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  color: white;

  font-size: 1.3rem;
}

.default-list {
  list-style-type: circle;
}

.method {
  display: inline;
  padding: 4px 9px;
  background-color: #ddd;
  border-radius: 3px;

  margin: 5px;

  &--get {
    background-color: dodgerblue;
    color: white;
  }

  &__post {
    background-color: #ffc71e;
    color: white;
  }

  &__put {
    background-color: #1eff29;
    color: white;
  }

  &__delete {
    background-color: #ff1e6d;
    color: white;
  }
}

table {
  & td {
    padding: 10px 20px;
    border: 1px solid #bbb;
  }
}