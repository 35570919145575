
@font-face {
  font-family: 'muliregular';
  src: url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-muli.eot?');
  src: url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-muli.eot?#iefix') format('embedded-opentype'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-muli.woff2') format('woff2'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-muli.woff') format('woff'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-muli.svg#muliregular') format('svg');
}

@font-face {
  font-family: 'helveticaregular';
  src: url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/helvetica.ttf');
}

@font-face {
  font-family: 'geomanistbold';
  src: url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/geomanistbold.eot?');
  src: url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/geomanistbold?#iefix') format('embedded-opentype'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/geomanistbold.woff2') format('woff2'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/geomanistbold.woff') format('woff'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/geomanistbold.svg') format('svg'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/geomanistbold.ttf') format('ttf');
}

@font-face {
  font-family: 'geomanistblack';
  src: url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-geomanistblack.eot?');
  src: url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-geomanistblack?#iefix') format('embedded-opentype'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-geomanistblack.woff2') format('woff2'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-geomanistblack.woff') format('woff'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-geomanistblack.svg') format('svg'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-geomanistblack.ttf') format('ttf');
}

@font-face {
  font-family: 'geomanistultra';
}

* {
  font-family: 'muliregular', 'helveticaregular', 'Lato', sans-serif;
  color: black;
  font-weight: normal;
  font-style: normal;
}
