
.navbar {

  height: 80px;
  padding: 0 55px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-width: 900px;

  border-bottom: 1px solid #ddd;
  //box-shadow: 0 0 10px black;

  &__right {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }

  &__left {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  &__logo {
    font-family: 'geomanistblack', 'Lato', sans-serif;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    width: 40px;
    height: 40px;
    margin: 10px;
    font-size: 1.2rem;
    border-radius: 3px;
    //text-shadow: 0 0 5px #20ecff;

  }

  &__title {
    font-size: 1.5rem;
  }

  &__menu {
    font-size: 1.5rem;
  }

}