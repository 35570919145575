
.api-layout {

  display: grid;

  grid-template-columns: 320px auto;
  grid-template-rows: auto;
  grid-template-areas: "navigation content";

  height: calc(100vh - 80px);

  &__navigation {
    grid-area: navigation;
    //background-color: aquamarine;
    padding: 30px 20px;
    overflow-y: scroll;

    & h1 {
      font-family: 'geomanistblack', 'Lato', sans-serif;
      font-size: 1.7rem;
      margin-bottom: 25px;
    }

    &__list {

      & li {
        padding: 10px 0;

        * {
          font-size: 1.3rem;
          color: #666;
        }

        &:hover {
          * {
            color: black;
          }
        }

      }

      &--selected {
        & * {
          color: black !important;
        }
      }
    }
  }

  &__content {
    grid-area: content;
    padding: 30px 60px;
    //background-color: #b3d4fc;
    overflow-y: scroll;
  }

}

.api-layout__title {
  font-size: 2.2rem;
  margin-bottom: 20px;
}

.api-layout__title_2 {
  font-size: 1.9rem;
  margin-bottom: 20px;
}